const colors = {
  brand: "#00747f",

  primaryText: "#4E5E74",
  secondaryText: "#ffffff",

  primaryBackground: "#FFF",
  secondaryBackground: "#4E5E74",
  pageBackground: "rgb(214, 221, 232, .3)",
  inputFieldBackground: "#FBFCFD",
  hoverBackground: "#F6FCFF",
  pageBodyBackground: "#F1F3F7",

  primaryBorder: "#D6DDE8",

  primaryShadow: "#0000000A",
  embossShadow: "#0000001A",

  good: "#46B984",
  caution: "#DD4B5B",
  ordinary: "#FFA000",
};

const fonts = {
  sanSerif: `'游ゴシック体', YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif`,
};

const fontSize = {
  xsmall: "12px",
  small: "14px",
  medium: "16px",
  large: "18px",
  xlarge: "20px",
  xxlarge: "26px",
};

const space = {
  xxsmall: "5px",
  xsmall: "10px",
  small: "15px",
  medium: "20px",
  large: "40px",
  xlarge: "80px",
  xxlarge: "100px",
};

const interactions = {
  primaryEasing: "ease-in-out",
  primaryDuration: ".4s",
};

export const defaultTheme = { colors, fonts, fontSize, space, interactions };

export type DefaultTheme = typeof defaultTheme;
