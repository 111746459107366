import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
`;

/**
 * Spinner to show when GraphQL requests are being made
 */
export const Loading: React.FC = () => (
  <Container>
    <FontAwesomeIcon icon={["fas", "spinner"]} color="#ccc" size="2x" spin />
  </Container>
);
