import React, { ReactNode } from "react";
import styled from "styled-components";

import { hexToRGB } from "../../utils/styles";

interface ButtonProps {
  /**
   * Enabled/disabled status
   */
  enabled?: boolean;
  /**
   * Children components (text or icon visuals)
   */
  children?: ReactNode;
  /**
   * HTML type attribute
   */
  type?: "button" | "submit" | "reset";
  /**
   * Optional click handler
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/**
 * Common UI component for upload forms and resource lists
 */
export const StyledButton = styled.button<{ enabled: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 44px;
  margin: 0 auto;
  color: ${(props) => props.theme.colors.secondaryText};
  background: ${(props) =>
    props.enabled === "false"
      ? hexToRGB(props.theme.colors.brand, 0.8)
      : props.theme.colors.brand};
  border: none;
  border-radius: 6px;
  text-decoration: none;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: bold;
  cursor: pointer;
  outline-style: none;
  transition: background
    ${(props) =>
      `${props.theme.interactions.primaryEasing} ${props.theme.interactions.primaryDuration}`};
  &:hover {
    background: ${(props) => hexToRGB(props.theme.colors.brand, 0.8)};
  }
`;

export const Button: React.FC<ButtonProps> = ({
  enabled = true,
  children,
  type,
  onClick,
}) => {
  const baseProps = { enabled: enabled.toString(), onClick };
  const props = type ? { ...baseProps, type } : baseProps;
  return <StyledButton {...props}>{children}</StyledButton>;
};
