import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { hexToRGB } from "../../utils/styles";
import { useMutation } from "@apollo/client";
import { CreateShippingRequestDocument } from "../../graphql/schema";

const Container = styled.div`
  position: relative;
  width: 260px;
  height: 44px;
  margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
`;

const DownloadLink = styled.button<{ loading: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.secondaryText};
  background: ${(props) =>
    props.loading === "true"
      ? hexToRGB(props.theme.colors.brand, 0.75)
      : props.theme.colors.brand};
  cursor: ${(props) => (props.loading === "true" ? "default" : "pointer")};
  text-decoration: none;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: bold;
  transition: background
    ${(props) =>
      `${props.theme.interactions.primaryEasing} ${props.theme.interactions.primaryDuration}`};
`;

export const CreateShippmentRequestButton: React.FC = () => {
  const [isButtonDisabledAfterDownload, setIsButtonDisabledAfterDownload] =
    useState(false);

  const [createShippingRequest, { loading }] = useMutation(
    CreateShippingRequestDocument,
    {
      onCompleted: (data) => {
        switch (data?.createShippingRequest.__typename) {
          case "ShippingRequestFile":
            // 仮想のa要素を作成してダウンロードを開始
            // 以下の理由により、window.open は使用していない
            //   ブラウザは、ユーザーの明示的な操作なしに新しいタブやウィンドウを開く試みを制限するためにポップアップブロッカーを使用しています。
            //   <a>タグのクリックは、ユーザーが直接行ったアクションとして解釈されやすいため、ポップアップブロッカーによる制約を受けにくいです。
            const downloadLink = document.createElement("a");
            downloadLink.href = data.createShippingRequest.csvSignedURL;
            downloadLink.setAttribute("download", "");
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            setIsButtonDisabledAfterDownload(true);
            break;
          case "ApsInternalError":
            window.alert(
              `エラーが発生しました。画面の更新を行い、再度データのダウンロードを試してください。\n message=${data.createShippingRequest.message} code=${data.createShippingRequest.code} `
            );
            break;
          case "EmptyUnshippedDeliveryOrderError":
            window.alert(data.createShippingRequest.message);
            break;
        }
      },
      onError: (error) => {
        window.alert(
          `エラーが発生しました。画面の更新を行い、再度データのダウンロードを試してください。\n message=${error.message}`
        );
      },
    }
  );

  useEffect(() => {
    if (isButtonDisabledAfterDownload) {
      const timer = setTimeout(() => {
        setIsButtonDisabledAfterDownload(false);
      }, 5000); // 5000ミリ秒 = 5秒
      return () => clearTimeout(timer);
    }
  }, [isButtonDisabledAfterDownload]);

  const disabled = loading || isButtonDisabledAfterDownload;

  return (
    <Container>
      <DownloadLink
        onClick={() => {
          createShippingRequest();
        }}
        disabled={disabled}
        loading={disabled.toString()}
      >
        受注データの受付
        <br />
        （データダウンロード）
      </DownloadLink>
    </Container>
  );
};
