import React, { useEffect } from "react";

// import { errorLogger } from "../../utils/logger";

export interface ErrorProps {
  /**
   * Message to display
   */
  message?: string;
  /**
   * Error to send to the logging platform (Sentry)
   */
  error: any;
  /**
   * Error category to send to the logging platform (Sentry)
   */
  category?: string;
}

/**
  Simple UI component to display the error message and send Error to the logging platform (Sentry)
 */
export const Error: React.FC<ErrorProps> = ({
  message,
  error,
  category,
}: ErrorProps) => {
  useEffect(() => {
    if (category) {
      // TODO: Sentryのエラーロギングを実装する
      // errorLogger(error, category);
      return;
    }
    // errorLogger(error);
  }, [error, category]);
  return <p>{message}</p>;
};
