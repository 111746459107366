import React from "react";
import { GlobalStyle } from "./utils/styles";
import { useAuth } from "./providers/auth/AuthContext";
import { Provider } from "./providers/Provider";
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { PageLayout } from "./components/ui/PageLayout";

export function App() {
  return (
    <Provider>
      <Page />
    </Provider>
  );
}

function Page() {
  const { isAuthenticated } = useAuth();

  return (
    <PageLayout>
      <GlobalStyle />
      {!!isAuthenticated && <Home />}
      {!isAuthenticated && <Login />}
    </PageLayout>
  );
}
