import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../utils/theme";
import { CognitoAuthProvider } from "./auth/CognitoAuthProvider";
import { ApolloProvider } from "@apollo/client";
import { useApolloClient } from "./apollo-client/useApolloClient";
import { useAwsConfig } from "./auth/useAwsConfig";

type ProviderProps = {
  children: ReactNode;
};

export function Provider({ children }: ProviderProps) {
  const awsConfig = useAwsConfig();

  if (!awsConfig) return null;

  return (
    <CognitoAuthProvider amplifyConfig={awsConfig}>
      <ApolloProviderWrapper>
        <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
      </ApolloProviderWrapper>
    </CognitoAuthProvider>
  );
}

function ApolloProviderWrapper({ children }: ProviderProps) {
  const { apolloClient } = useApolloClient();
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
