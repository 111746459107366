import React, { ReactNode } from "react";
import styled from "styled-components";

import { hexToRGB } from "../../utils/styles";

export interface SectionProps {
  /**
   * Title for the section
   */
  title: string;
  /**
   * Children component for the content area
   */
  children?: ReactNode;
}

const Container = styled.section`
  margin: ${(props) => props.theme.space.medium};
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  box-shadow: 0px 2px 6px #0000000a;
`;

const Heading = styled.h2`
  display: flex;
  align-items: center;
  position: relative;
  height: 58px;
  padding-left: 54px;
  padding-right: 40px;
  border-bottom: 2px solid
    ${(props) => hexToRGB(props.theme.colors.primaryBorder, 0.4)};
  border-radius: 20px 20px 0 0;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: bold;
  &::after {
    content: "";
    position: absolute;
    left: 40px;
    top: 20px;
    width: 6px;
    height: 18px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.brand};
  }
`;

const Content = styled.div`
  padding: ${(props) => props.theme.space.medium};
`;

/**
 * Container for a group of content
 */
export const Section: React.FC<SectionProps> = ({ title, children }) => {
  return (
    <Container>
      <Heading>{title}</Heading>
      <Content>{children}</Content>
    </Container>
  );
};
