import React from "react";
import { Header } from "../components/feature/Header";
import { Navigation } from "../components/feature/Navigation";
import { Section } from "../components/ui/Section";
import { CreateShippmentRequestButton } from "../components/feature/CreateShippmentRequestButton";
import { ShipmentRequestDownloadList } from "../components/feature/ShipmentRequestDownloadList";
import { Main } from "../components/ui/Main";

export const Home: React.FC = () => {
  return (
    <>
      <Header />
      <Navigation />
      <Main>
        <Section title="受注データ">
          <CreateShippmentRequestButton />
        </Section>
        <Section title="過去の受注データ一覧">
          <ShipmentRequestDownloadList />
        </Section>
      </Main>
    </>
  );
};
