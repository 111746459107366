import React, { useState } from "react";
import styled from "styled-components";

import logo from "../../assets/images/logo_cureapp.svg";
import hamburger from "../../assets/images/hamburger.svg";
import { hexToRGB } from "../../utils/styles";
import { useAuth } from "../../providers/auth/AuthContext";
import { Menu } from "./Menu";

const Container = styled.header`
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding-left: ${(props) => props.theme.space.medium};
  padding-right: ${(props) => props.theme.space.medium};
  border-bottom: 2px solid ${(props) => hexToRGB(props.theme.colors.brand, 0.2)};
  background-color: ${(props) => props.theme.colors.primaryBackground};
`;

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;

const Username = styled.div`
  display: flex;
  align-items: center;
  height: 33px;
  padding: ${(props) =>
    `${props.theme.space.xsmall} ${props.theme.space.small}`};
  border-right: 1px solid ${(props) => props.theme.colors.primaryBorder};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize.small};
`;

const Hamburger = styled.div.attrs(() => ({ tabIndex: 0 }))`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
  height: 33px;
  margin-left: ${(props) => props.theme.space.small};
  cursor: pointer;
  outline-style: none;
  transition: opacity
    ${(props) =>
      `${props.theme.interactions.primaryEasing} ${props.theme.interactions.primaryDuration}`};
  &:hover {
    opacity: 0.7;
  }
`;

/**
 * Header banner area with the user name and hamburger button
 */
export const Header: React.FC = () => {
  const { user } = useAuth();
  const [menuVisibility, setMenuVisibility] = useState<boolean>(false);

  const onMenuToggleClick = (menuVisibility: boolean) => {
    return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      setMenuVisibility(!menuVisibility);
    };
  };

  return (
    <>
      <Container role="banner">
        <LeftColumn>
          <img src={logo} width="140" height="29" alt={"CureApp Logo"} />
        </LeftColumn>
        <RightColumn>
          <LeftColumn>
            <Username>{user?.name}</Username>
          </LeftColumn>
          <RightColumn>
            <Hamburger
              id="menu"
              role="button"
              aria-label="Open menu"
              onClick={onMenuToggleClick(menuVisibility)}
            >
              <img src={hamburger} width="24" height="24" alt={"Menu"} />
            </Hamburger>
          </RightColumn>
        </RightColumn>
      </Container>
      {menuVisibility && (
        <Menu onMenuToggleClick={onMenuToggleClick(menuVisibility)} />
      )}
    </>
  );
};
