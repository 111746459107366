import React from "react";
import { useState, useEffect } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";

import { Auth } from "@aws-amplify/auth";
import Amplify from "@aws-amplify/auth";
import { AuthContext } from "./AuthContext";
import { ReactNode } from "react";
import { User } from "./AuthState";

interface CognitoAuthProviderProps {
  amplifyConfig: {
    Auth: {
      region: string;
      userPoolId: string;
      userPoolWebClientId: string;
      oauth: {
        domain: string;
        scope: string[];
        redirectSignIn: string;
        redirectSignOut: string;
        responseType: string;
      };
    };
  };
  children: ReactNode;
}

export function CognitoAuthProvider(props: CognitoAuthProviderProps) {
  Amplify.configure(props.amplifyConfig);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    checkAuthenticated();
    currentAuthenticatedUser();
  }, []);

  const checkAuthenticated = () => {
    setIsLoading(true);
    Auth.currentSession()
      .then((data) => {
        if (data) setIsAuthenticated(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const currentAuthenticatedUser = async (): Promise<void> => {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    cognitoUser?.getUserData((_err, result) => {
      const given_name =
        result?.UserAttributes.find((value) => value.Name === "given_name")
          ?.Value ?? "";
      const family_name =
        result?.UserAttributes.find((value) => value.Name === "family_name")
          ?.Value ?? "";

      setUser({ cognitoUser, name: `${family_name} ${given_name}` });
    });
  };

  const signIn = (): void => {
    Auth.federatedSignIn();
  };

  const signOut = () => {
    Auth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        signIn,
        signOut,
        user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
