import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isSameOrBeforePlugin from "dayjs/plugin/isSameOrBefore";

const JAPAN_TIMEZONE = "Asia/Tokyo";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(isBetween);
dayjs.tz.setDefault(JAPAN_TIMEZONE);

export const formatInJst = (date: Date, formatTemplate: string) => {
  return dayjs(date).tz().format(formatTemplate);
};
