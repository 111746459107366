import React from "react";
import { useAuth } from "../providers/auth/AuthContext";
import { Button } from "../components/ui/Button";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0;
`;

export const Login: React.FC = () => {
  const { signIn } = useAuth();

  return (
    <Container>
      <Button onClick={signIn}>ログイン</Button>
    </Container>
  );
};
