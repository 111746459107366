import { hexToRGB } from "../../../src/utils/styles";
import styled from "styled-components";

export const HeaderRow = styled.tr`
  background: ${(props) =>
    hexToRGB(props.theme.colors.secondaryBackground, 0.08)};
`;
export const HeaderCell = styled.th`
  height: 45px;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: bold;
  vertical-align: middle;
  text-align: left;
  padding: ${(props) =>
    `${props.theme.space.xsmall} ${props.theme.space.xlarge}`};
`;
export const TableRow = styled.tr`
  border-bottom: 1px solid
    ${(props) => hexToRGB(props.theme.colors.primaryBorder, 0.4)};
`;
export const TableCell = styled.td`
  height: 58px;
  vertical-align: middle;
  text-align: ${(props) => props.align || "left"};
  padding: ${(props) =>
    `${props.theme.space.xsmall} ${props.theme.space.xlarge}`};
`;
