import React from "react";

import { Navigation as Component, NavigationListItem } from "../ui/Navigation";

const natvigationItems = [
  {
    id: "deliveryOrder",
    label: "受注データ",
    path: "/",
  },
];

export const Navigation = () => {
  const navigationItemsData = natvigationItems.map(
    (item): NavigationListItem => ({
      id: item.id,
      label: item.label,
      path: item.path,
      // TODO: Navigationの項目が増えたら、現在パスと比較する
      isSelected: item.path === "/",
    })
  );
  return <Component navigationItems={navigationItemsData} />;
};
