import React from "react";
import { HeaderCell, HeaderRow, TableCell, TableRow } from "../ui/Table";
import styled from "styled-components";
import { GQLDELIVERYShippingRequestFile } from "../../graphql/schema";
import { formatInJst } from "../../utils/time";

const Container = styled.table`
  border-collapse: collapse;
  box-sizing: content-box;
  width: 100%;
  margin-bottom: ${(props) => props.theme.space.medium};
  border-radius: 6px 6px 0 0;
  overflow: hidden;
`;

const DownloadHistoryLink = styled.a`
  color: ${(props) => props.theme.colors.brand};
  text-decoration: none;
  font-weight: bold;
`;

const formatCreatedAt = (createdAt: string) =>
  formatInJst(new Date(createdAt), "YYYY/MM/DD HH:mm:ss");

interface ShipmentRequestDownloadListTableProps {
  rows: GQLDELIVERYShippingRequestFile[];
}
export const ShipmentRequestDownloadListTable: React.FC<
  ShipmentRequestDownloadListTableProps
> = ({ rows }) => {
  const headers = ["ファイル名", "作成日時", ""];
  return (
    <>
      <Container>
        <thead>
          <HeaderRow>
            {headers.map((header) => (
              <HeaderCell key={header}>{header}</HeaderCell>
            ))}
          </HeaderRow>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{row.filename}</TableCell>
              <TableCell>{formatCreatedAt(row.createdAt)}</TableCell>
              <TableCell align="right">
                <DownloadHistoryLink
                  href={row.csvSignedURL}
                  download={row.filename}
                >
                  再ダウンロード
                </DownloadHistoryLink>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Container>
    </>
  );
};
