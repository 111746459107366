import React from "react";
import ReactDOM from "react-dom";
import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { App } from "./App";
import { SENTRY_DSN, VERSION } from "./settings";
import { browserTracingIntegration, init } from "@sentry/react";

if (SENTRY_DSN) {
  init({
    dsn: SENTRY_DSN,
    release: VERSION,
    // 公式ドキュメントでは本番環境のtrancesSampleRateを下げることを推奨しているが、
    // 今回は状況把握およびトラブルシューティングに活かすために全てのイベントをトレースするように1.0（100%）を指定している。
    // コスト面を考慮する場合は適宜tracesSampleRateを下げてください。
    // see: https://docs.sentry.io/platforms/javascript/performance/#configure
    tracesSampleRate: 1.0,
    integrations: [browserTracingIntegration()],
  });
}

library.add(faSpinner as IconDefinition);

ReactDOM.render(<App />, document.getElementById("react-root"));
