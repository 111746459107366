import { CognitoUser } from "amazon-cognito-identity-js";

export type User = {
  cognitoUser: CognitoUser;
  name: string;
};
export interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
}
const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
};
const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <CognitoAuthProvider>."
  );
};
export const initialContext = {
  ...initialState,
  signIn: stub,
  signOut: stub,
};
