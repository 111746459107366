import React from "react";
import styled from "styled-components";

export interface NavigationListItem {
  id: string;
  label: string;
  path: string;
  isSelected: boolean;
}
export interface NavigationListProps {
  /**
   * Navigation item data (`label`, `isSelected` and `path`)
   */
  navigationItems: NavigationListItem[];
}

const Container = styled.nav`
  align-self: flex-start;
  width: 100%;
  padding-left: ${(props) => props.theme.space.medium};
  padding-right: ${(props) => props.theme.space.medium};
  background-color: ${(props) => props.theme.colors.primaryBackground};
  box-shadow: 0px 4px 8px #4e5e741a;
`;

const NavigationListItem = styled.li<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  height: 60px;
  margin-right: ${(props) => props.theme.space.large};
  border-bottom: 4px solid
    ${(props) =>
      props.isSelected
        ? props.theme.colors.brand
        : props.theme.colors.primaryBackground};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize.medium};
  cursor: pointer;
  & a {
    display: flex;
    align-items: center;
    height: 56px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primaryText};
    transition: opacity
      ${(props) =>
        `${props.theme.interactions.primaryEasing} ${props.theme.interactions.primaryDuration}`};
      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

const NavigationList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
`;

/**
 * UI component that holds links to internal pages
 */
export const Navigation: React.FC<NavigationListProps> = ({
  navigationItems,
}) => {
  return (
    <Container>
      <NavigationList>
        {navigationItems.map((item) => (
          <NavigationListItem isSelected={item.isSelected} key={item.id}>
            <a>{item.label}</a>
          </NavigationListItem>
        ))}
      </NavigationList>
    </Container>
  );
};
