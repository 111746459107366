import {
  COGNITO_CLIENT_ID,
  COGNITO_DOMAIN,
  COGNITO_REDIRECT_URL,
  COGNITO_USERPOOL_ID,
} from "../../settings";

export function useAwsConfig() {
  if (
    !COGNITO_USERPOOL_ID ||
    !COGNITO_CLIENT_ID ||
    !COGNITO_DOMAIN ||
    !COGNITO_REDIRECT_URL
  ) {
    // TODO: sentryへエラー通知する
    return null;
  }

  const awsConfig = {
    Auth: {
      region: "ap-northeast-1",
      userPoolId: COGNITO_USERPOOL_ID,
      userPoolWebClientId: COGNITO_CLIENT_ID,
      oauth: {
        domain: COGNITO_DOMAIN,
        scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
        redirectSignIn: COGNITO_REDIRECT_URL,
        redirectSignOut: COGNITO_REDIRECT_URL,
        responseType: "code",
      },
    },
  };

  return awsConfig;
}
