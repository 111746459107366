import React from "react";
import styled from "styled-components";
// import { AmplifySignOut } from "@aws-amplify/ui-react";

import profileImagePlaceholder from "../../assets/images/user_placeholder.png";
import { hexToRGB } from "../../utils/styles";
import { useAuth } from "../../providers/auth/AuthContext";

export interface MenuProps {
  /**
   * Click Handler to close the menu
   */
  onMenuToggleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: "block";
  width: 100vw;
  height: 100%;
  z-index: 1;
`;
const Container = styled.nav`
  display: "block";
  position: absolute;
  right: 20px;
  z-index: 2;
  top: 50px;
  width: 240px;
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  box-shadow: 8px 8px 8px #4e5e740f;
`;
const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 20px 30px;
  border-bottom: 2px solid
    ${(props) => hexToRGB(props.theme.colors.primaryBorder, 0.4)};
`;
const ProfileImage = styled.img`
  display: block;
  margin-bottom: 15px;
  width: 72px;
  height: auto;
  margin-bottom: 20px;
`;
const UserName = styled.p`
  display: block;
  margin: 0;
  font-weight: bold;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  cursor: pointer;
`;

export const Menu: React.FC<MenuProps> = ({ onMenuToggleClick }) => {
  const { signOut, user } = useAuth();
  return (
    <>
      <Overlay onClick={onMenuToggleClick} />
      <Container>
        <ProfileContainer>
          <ProfileImage src={profileImagePlaceholder} />
          <UserName>{user?.name}</UserName>
        </ProfileContainer>
        <ButtonContainer onClick={signOut}>
          <p>ログアウト</p>
        </ButtonContainer>
      </Container>
    </>
  );
};
