import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset}
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  body {
    position: relative;
    font-family: ${({ theme }) => theme.fonts.sanSerif};
    color: ${({ theme }) => theme.colors.primaryText};
    line-height: 1.2;
    background-color: ${(props) => props.theme.colors.pageBackground};
    --amplify-primary-color: ${({ theme }) => theme.colors.brand};
    --amplify-primary-shade: ${({ theme }) => theme.colors.brand};
    --amplify-primary-tint: ${(props) =>
      hexToRGB(props.theme.colors.brand, 0.8)};
  }
  button {
    padding: 0;
  }
`;

export const hexToRGB = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};
