import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { APS_DELIVERY_BACKEND_URL } from "../../settings";
import { useAuth } from "../auth/AuthContext";
import { concatPagination } from "@apollo/client/utilities";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        shippingRequestFiles: concatPagination(),
      },
    },
  },
});

export const useApolloClient = () => {
  const { user } = useAuth();
  const token = user?.cognitoUser
    ?.getSignInUserSession()
    ?.getAccessToken()
    .getJwtToken();

  const httpLink = createHttpLink({
    uri: APS_DELIVERY_BACKEND_URL,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return {
    apolloClient: new ApolloClient({
      cache,
      link: ApolloLink.from([authLink, httpLink]),
    }),
  };
};
