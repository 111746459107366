import React from "react";
import { ShipmentRequestDownloadListTable } from "./ShipmentRequestDownloadListTable";
import { Button } from "../ui/Button";
import { usePaginatedShippingRequestFilesQuery } from "../../graphql/queries";
import { Error } from "../ui/Error";
import { Loading } from "../ui/Loading";
import {
  GQLDELIVERYQueryOrderValue,
  GQLDELIVERYShippingRequestFilesOrderField,
  GQLDELIVERYQuery,
} from "../../graphql/schema";

// ヤマト配送基盤は setting.ts で PAGENATION_LIMIT として定義されている
const PAGENATION_LIMIT = 20;

const onLoadMoreClick = (
  fetchMore: ReturnType<
    typeof usePaginatedShippingRequestFilesQuery
  >["fetchMore"],
  data?: GQLDELIVERYQuery
) => {
  return async () => {
    const moreShippingRequestFilesData = await fetchMore({
      variables: {
        input: {
          limit: PAGENATION_LIMIT,
          offset: data?.shippingRequestFiles.length,
          order: {
            field: GQLDELIVERYShippingRequestFilesOrderField.CreatedAt,
            value: GQLDELIVERYQueryOrderValue.Desc,
          },
        },
      },
    });

    if (moreShippingRequestFilesData.data?.shippingRequestFiles.length === 0) {
      // eslint-disable-next-line no-alert
      alert("受注ダウンロード履歴は全て表示されています");
    }
  };
};

export const ShipmentRequestDownloadList: React.FC = () => {
  const { data, error, loading, fetchMore } =
    usePaginatedShippingRequestFilesQuery({
      variables: {
        input: {
          limit: PAGENATION_LIMIT,
          offset: 0,
          order: {
            field: GQLDELIVERYShippingRequestFilesOrderField.CreatedAt,
            value: GQLDELIVERYQueryOrderValue.Desc,
          },
        },
      },
    });

  if (error) {
    return <Error message="リストの取得に失敗しました" error={error} />;
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ShipmentRequestDownloadListTable
        rows={data?.shippingRequestFiles ?? []}
      />
      <Button onClick={onLoadMoreClick(fetchMore, data)}>さらに表示</Button>
    </>
  );
};
